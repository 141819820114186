<template>
  <div
    class="lg:max-w-contact-form-container mx-auto mb-6 md:mb-10 w-full lg:mt-16 lg:px-4"
  >
    <div
      class="hidden lg:text-s-900 lg:flex w-full justify-center gap-5 py-2 text-sm font-semibold text-white md:text-lg lg:relative lg:top-0 lg:justify-start lg:bg-transparent"
    >
      <AccountTabMenu
        v-for="tab in tabs"
        :id="tab.id"
        :key="tab.name"
        :item="tab"
      />
    </div>

    <MobileSimpleMenu class="block lg:hidden" :menus="mobileMenus" />
  </div>
</template>

<script setup lang="ts">
import AccountTabMenu from '~/components/account/TabMenu.vue'
import MobileSimpleMenu from '~/components/navigation/MobileSimpleMenu.vue'

const tabs = [
  {
    title: 'My Account',
    name: 'Account Details',
    to: '/account',
    id: 'account-details-tab',
  },
  {
    title: 'Current Subscriptions',
    name: 'Subscriptions',
    to: '/account/subscriptions',
    id: 'current-subscriptions-tab',
  },
  {
    title: 'API Access',
    name: 'API Access',
    to: '/account/api-access',
    id: 'api-access-tab',
  },
]

const mobileMenus = tabs.map((tab) => ({
  name: tab.name,
  href: tab.to,
}))

const $route = useRoute()

const title = computed(() => {
  const item = tabs.find((i) => i.to === $route.path.replace(/\/$/, ''))
  return item?.title || 'My Account'
})

const { getSeoInfo } = useSeo()
const seo = getSeoInfo({
  title: title.value,
  description:
    'Benchmark enables the most critical decisions of the energy transition',
})

useHead(seo)
</script>
