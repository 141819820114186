<template>
  <TheLayout class="md:bg-s-50">
    <AccountHeader :key="$route.fullPath" />

    <div class="max-w-contact-form-container mx-auto w-full p-4 pt-0">
      <div class="mb-20 flex-grow">
        <slot />
      </div>
    </div>
  </TheLayout>
</template>

<script setup lang="ts">
import AccountHeader from '~/components/account/Header.vue'
import TheLayout from '~/components/TheLayout.vue'

const $route = useRoute()
</script>
